import React from 'react';
import Layout from '../components/layout.component';
import styled from 'styled-components';

const GrievanceRedressal = () => {
    return (
      <Layout>
        <MainWrapper>
          <p className="GrievanceRedressal-heading">
            Ufaber Grievance Redressal Mechanism
          </p>
          <div className="GrievanceRedressal-TopDiv">
            <p>
              This is the official page where you can raise any grievances that
              you may have about Ufaber’s learning programs.
            </p>
            <p>
              We, at Ufaber, value the trust placed upon us and strive to offer
              the best experience to You. Our Grievance Redressal Mechanism has
              been designed to resolve customer grievances in a timely and
              efficient manner, while treating our customers fairly and
              courteously.{" "}
            </p>
            <p>
              In order to meet the above objective, Ufaber has outlined a
              framework for redressal of customer grievances and documented it
              in the form of a policy for the reference of customer
              touch-points.{" "}
            </p>
            <p>
              Ufaber shall ensure that the Customer Grievance Redressal Policy
              is also available in public domain (on its app, website, and at
              branches).
            </p>
          </div>
          <p className="TitleText">Scope and Objectives</p>
          <div className="scopeAndObjectivesWrapper">
            <p>
              The objective of the policy is to spell out the framework for
              Grievance Redressal in Ufaber to ensure that:
            </p>
            <ol>
              <li>
                All customers are treated fairly and in an unbiased manner at
                all times
              </li>
              <li>
                All issues raised by customers are dealt with courtesy and
                resolved on time
              </li>
              <li>
                Customers are made completely aware of avenues to escalate their
                grievance within Ufaber and their rights to alternate remedies
                if they are not fully satisfied with the response or resolution
                to their grievance
              </li>
              <li>
                The employees shall work in good faith and without prejudice
                towards the interest of the customer
              </li>
            </ol>
            <p>
              Through this policy, Ufaber shall ensure that a suitable mechanism
              exists for receiving and addressing grievances from its customers,
              with specific emphasis on resolving such cases fairly and
              expeditiously regardless of the source of the case.
            </p>
          </div>
          <p className="TitleText">Tenets of grievance redressal:</p>
          <div className="tenetsOfGrievanceWrapper">
            <p>
              Ufaber shall be guided by the following tenets in its approach to
              grievance redressal:
            </p>
            <ol>
              <li>
                <span>Customer Awareness:</span> Ufaber shall endeavor to make
                continuous efforts to educate its customers to enable them to
                make informed choices regarding banking products as well as
                channels to approach for grievance redressal.
              </li>
              <li>
                <span>Fairness &amp; transparency:</span> The customer’s
                grievance shall be examined in all fairness and the Ufaber shall
                take a balanced approach to resolve the same. Ufaber shall
                ensure customer grievances are resolved in a timely and
                efficient manner.
              </li>
              <li>
                <span>Escalation:</span> All responses to customer grievances
                will provide an escalation matrix mentioning the details of the
                next level of grievance redressal for the customer.
              </li>
              <li>
                <span>Review:</span> Ufaber shall have a regular process of
                internal review of customer grievances at multiple hierarchies
                to enhance Quality and effectiveness of customer service.
              </li>
            </ol>
          </div>
          <p className="TitleText">
            Process of handling general customer grievances of Ufaber
          </p>
          <div className="handlingCustomerGrievancesWrapper">
            <p>
              Touch points to report customer grievances OR available for
              referring/seeking redressal of grievances are listed below:
            </p>
            <p>
              Student can directly email their grievances to contact@ufaber.com
              OR may call on helpline no. 9321943330 to register their grievance
            </p>
            <p>Under Student Portal Login:</p>
            <p>
              There is an option to Raise a Complaint through which students can
              register their grievance.
            </p>
          </div>
          <p className="TitleText">
            Logging and tracking of customer grievances:
          </p>
          <div className="loggingAndTrackingGrievancesWrapper">
            <p>
              Any grievance received - either verbally, by email or in writing,
              if not resolved and responded the same day, shall be logged by
              Ufaber into the Internal CRM Tool - our customer grievance
              tracking system.
            </p>
            <p>
              With this Ufaber shall not only ensure that all the issues are
              recorded and resolved, but shall also ensure effective monitoring
              /escalation mechanism to the senior functionary responsible in
              case of the grievance not being resolved within defined timelines.
            </p>
            <p>
              Grievance resolution Turnaround time: Depending on the nature of
              the grievance, specific timelines have been set for different
              categories. Grievances shall be resolved in a proper and time
              bound manner with detailed advice to the customer. The customer is
              kept informed in case of any delay envisaged by Ufaber in
              resolution of the grievance beyond the stated timelines.
            </p>
          </div>
          <p className="TitleText">Resolution of Complaints</p>
          <div className="resolutionOfComplaintsWrapper">
            <ol>
              <li>
                We are committed to providing you with our first response within
                24 hours of receiving the query / complaint.
              </li>
              <li>
                We aim to resolve all Your complaints/queries within 3 business
                days.
              </li>
              <li>
                Resolution of Your complaint may get delayed due to operational
                or technical reasons. In such a scenario, You will be
                proactively informed of the timelines during which Your
                complaint will be addressed.
              </li>
            </ol>
          </div>
          <p className="TitleText">Mode of response:</p>
          <div className="modeOfResponseWrapper">
            <p className="modeOfResponseText">
              Ufaber shall ensure that the mode of response is as per the mode
              of customer intimation received e.g. cases received through email
              shall be responded through email.
            </p>
          </div>
          <p className="TitleText">Escalation of grievances:</p>
          <div className="escalationOfGrievancesWrapper">
            <p>
              Ufaber has a three-tier escalation mechanism for customer
              grievances, as given below:
            </p>
            <p>
              Level 1 - Customer Support – Call us on or email at{" "}
              <a href="mailto : contact@ufaber.com">contact@ufaber.com</a>
            </p>
            <p>
              Level 2 - Customer Success Manager - Call us on{" "}
              <a href="tel: +91 9321943330">+91 9321943330</a> or email at{" "}
              <a href="mailto: edwin.gf@ufaber.com ">edwin.gf@ufaber.com</a> /{" "}
              <a href="mailto: sameer@ufaber.com">sameer@ufaber.com</a>
            </p>
            <p>
              Level 3 - Nodal Officer and Grievance Redressal Officer- Ufaber
              has appointed a Nodal Officer and Grievance Redressal Officer
              whose contact details are as below-
            </p>
            <p>
              <span>Nodal officer-</span>
              <br />
              Name- Mr. Sameer Shah <br />
              Email ID -{" "}
              <a href="mailto: sameer@ufaber.com">sameer@ufaber.com</a>
            </p>
            <p>
              <span>Grievance Redressal Officer-</span>
              <br />
              Name- Mr. Edwin fernandes
              <br />
              Email Id-{" "}
              <a href="mailto: edwin.gf@ufaber.com">edwin.gf@ufaber.com</a>
            </p>
            <p>
              At Ufaber, we strive to address and resolve issues in the shortest
              possible time and in the most seamless way. We aim to deliver the
              best learning experience and level of service to each and every
              student.
            </p>
          </div>
        </MainWrapper>
      </Layout>
    );
}
const MainWrapper = styled.main`
    padding: 56px 16px;
    width: 100%;
    max-width: 1156px;
    margin: 4rem auto;
    .GrievanceRedressal-heading {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        text-decoration-line: underline;
        color: #354b60;
        margin-bottom: 28px;
    }
    .GrievanceRedressal-TopDiv {
        margin-bottom: 44px;
    }
    .GrievanceRedressal-TopDiv p:nth-child(1) {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 28px;
    }
    .GrievanceRedressal-TopDiv p:nth-child(2) {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .GrievanceRedressal-TopDiv p:nth-child(3) {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .GrievanceRedressal-TopDiv p:nth-child(4) {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
    }
    .TitleText {
        margin-bottom: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        color: #354b60;
    }
    .scopeAndObjectivesWrapper {
        margin-bottom: 44px;
    }
    .scopeAndObjectivesWrapper p:nth-of-type(1) {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .scopeAndObjectivesWrapper p:nth-of-type(2) {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
    }
    .scopeAndObjectivesWrapper ol {
        padding-left: 20px;
        margin-bottom: 12px;
    }
    .scopeAndObjectivesWrapper ol li {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
    }
    .tenetsOfGrievanceWrapper {
        margin-bottom: 44px;
    }
    .tenetsOfGrievanceWrapper p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .tenetsOfGrievanceWrapper ol {
        padding-left: 20px;
        margin-bottom: 12px;
    }
    .tenetsOfGrievanceWrapper ol li {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
    }
    .tenetsOfGrievanceWrapper ol li span {
        font-weight: 600;
        display: inline-block;
    }
    .handlingCustomerGrievancesWrapper {
        margin-bottom: 44px;
    }
    .handlingCustomerGrievancesWrapper ul {
        padding-left: 32px;
        margin-bottom: 12px;
        color: #007bff;
    }
    .handlingCustomerGrievancesWrapper p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .handlingCustomerGrievancesWrapper p:nth-of-type(3) {
        margin-bottom: 0;
    }
    .handlingCustomerGrievancesWrapper ul li a {
        display: block;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-decoration-line: underline;
        color: #007bff;
        word-break: break-all;
    }
    .loggingAndTrackingGrievancesWrapper {
        margin-bottom: 44px;
    }
    .loggingAndTrackingGrievancesWrapper p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .loggingAndTrackingGrievancesWrapper p:nth-of-type(3) {
        margin-bottom: 0;
    }
    .resolutionOfComplaintsWrapper {
        margin-bottom: 44px;
    }
    .resolutionOfComplaintsWrapper ol {
        padding-left: 20px;
    }
    .resolutionOfComplaintsWrapper ol li {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
    }
    .modeOfResponseText {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
    }
    .modeOfResponseWrapper {
        margin-bottom: 44px;
    }
    .escalationOfGrievancesWrapper p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #354b60;
        margin-bottom: 12px;
    }
    .escalationOfGrievancesWrapper p a {
        color: #007bff;
        text-decoration: underline !important;
    }
    .escalationOfGrievancesWrapper p:nth-of-type(4) {
        margin-bottom: 16px;
    }
    .escalationOfGrievancesWrapper p:nth-of-type(7) {
        margin-bottom: 0;
    }
    .escalationOfGrievancesWrapper p span {
        font-weight: 600;
    }
`;
export default GrievanceRedressal;
